<div class="h-full">
	<app-topbar></app-topbar>
	<div class="content-wrapper">
		<app-sidebar></app-sidebar>
		<div class="content flex-1 h-full">
			<router-outlet></router-outlet>
		</div>
	</div>
</div>

<p-toast position="bottom-center"></p-toast>

@if($showNetworkAlert()) {
	<div class="page-overlay-text">
		<i class="pi pi-exclamation-triangle" style="font-size: 6em"></i>
		<h2>Připojení k internetu není k dispozici</h2>
		<h3 style="margin-top: 0">Pro používání aplikace se připoj k internetu</h3>
	</div>
}
