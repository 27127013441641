<p-toolbar>
	<div class="p-toolbar-group-start">
		<p-button
			(click)="toggleSidebar()"
			icon="pi pi-fw pi-bars"
			styleClass="p-button-outlined"
			class="mr-2"
		></p-button>

		<h3>{{ layoutService.$topBarTitle() }}</h3>
	</div>
</p-toolbar>
