<div class="flex flex-column justify-content-center">
	<div class="p-inputgroup">
		<input
			type="password"
			pInputText
			[value]="password"
		/>
		<button
			type="button"
			pButton
			icon="pi pi-delete-left"
			styleClass="p-button-danger"
			(click)="removeNumber()"
		></button>
	</div>

	<div class="numpad mt-5">
		@for (number of numpad; track number) {
		<p-button
			class="numpad-item"
			[outlined]="true"
			[label]="number"
			(click)="addNumber(number)"
		/>
		}
	</div>

	<p-button
		severity="success"
		size="large"
		label="Přihlásit se"
		[style]="{ width: '100%' }"
		class="mt-5"
		(click)="login()"
	/>
</div>
